import { createRoot } from 'react-dom/client';

import App from './App';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('root 엘리먼츠 어디갔냐?');
}

const root = createRoot(rootElement);

root.render(<App />);
