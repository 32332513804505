import styled from 'styled-components';

import WaitListButton from './components/WaitListButton';

import typography from './style/typography';
import mq from './style/mq';

function WantWaitSection() {
  return (
    <Wrapper>
      <Container>
        <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/papyrus-favicon.png" alt="파피루스" />
        <Description>
          <p>
            논문 정리는 파피루스에게 맡기고
            {' '}
            <strong>공부에만 집중하세요</strong>
          </p>
          <p>
            파피루스는 현재 사전 신청자를 모집하고 있어요. 🙇
            {'\n'}
            월 19,900원에 지금 당장 신청해보세요.
          </p>
          <WaitListButton />
        </Description>
      </Container>
    </Wrapper>
  );
}

export default WantWaitSection;

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fbfae1;
  padding-block: 120px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-inline: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;

  img {
    width: 200px;
    height: auto;

    ${mq.lessThan('medium', `
      display: none;0
    `)}
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  justify-content: center;

  p:first-of-type {
    ${typography.title}
    color: #212121;
  }

  p:last-of-type {
    ${typography.description}
    color: #333;
    white-space: pre-wrap;
  }

  ${mq.lessThan('medium', `
    align-items: center;
    
    p {
      text-align: center;
    }

    strong {
      margin-top: 0.3em;
      display: block;
    }
  `)}
`;
