import styled from 'styled-components';

import typography from './style/typography';
import mq from './style/mq';

const featureImageUrls = {
  send: 'https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/papyrus/send.gif',
  referExtract: 'https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/papyrus/refer-extract.gif',
  detailPage: 'https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/papyrus/detail-page.gif',
  search: 'https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/papyrus/search.gif',
};

function FeaturesSection() {
  return (
    <Wrapper>
      <Title>
        <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/papyrus-favicon.png" alt="파피루스" />
        나만의
        {' '}
        <strong>Archive AI</strong>
        ,
        {' '}
        <span>파피루스</span>
      </Title>
      <Features>
        <Feature>
          <Description>
            <p>
              <img src="https://img.icons8.com/ios/100/FAB005/sent--v1.png" alt="sent--v1" />
              손쉬운 논문 전송
            </p>
            <p>
              카톡 및 크롬 익스텐션으로 PDF만 보내면 끝!
              {'\n'}
              나머지는 파피루스가 다 해드립니다.
            </p>
          </Description>
          <FeatureImage src={featureImageUrls.send} />
        </Feature>
        <Divider />
        <Feature>
          <Description>
            <p>
              <img src="https://img.icons8.com/ios/100/FAB005/type.png" alt="type" />
              레퍼 정보 자동 추출
            </p>
            <p>
              AI가 저자, 연도, 제목 등 레퍼 정보를 자동 추출,
              {'\n'}
              주제까지 파악해 폴더구조로 알아서 정리해드립니다.
            </p>
          </Description>
          <FeatureImage src={featureImageUrls.referExtract} />
        </Feature>
        <Divider />
        <Feature>
          <Description>
            <p>
              <img src="https://img.icons8.com/ios/100/FAB005/brief.png" alt="brief" />
              논문 요약 및 번역
            </p>
            <p>
              논문 구조에 맞는 한글 요약과 실시간 번역을 통해
              {'\n'}
              어떠한 논문이든 빠른 이해를 도와드립니다.
            </p>
          </Description>
          <FeatureImage src={featureImageUrls.detailPage} />
        </Feature>
        <Divider />
        <Feature>
          <Description>
            <p>
              <img src="https://img.icons8.com/pastel-glyph/64/FAB005/search-in-browser--v2.png" alt="search-in-browser--v2" />
              유사도 기반 논문 검색
            </p>
            <p>
              내용만 희미하게 남아있던 논문들,
              {'\n'}
              지금 당장 필요한 그 논문을 찾아드립니다!
            </p>
          </Description>
          <FeatureImage src={featureImageUrls.search} />
        </Feature>
      </Features>
    </Wrapper>
  );
}

export default FeaturesSection;

const Wrapper = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 100px;
    gap: 64px;
`;

const Title = styled.h4`
  font-size: 48px;
  font-weight: 800;

  img {
    height: 32px;
    width: auto;
    margin-right: 8px;
  }

  strong {
    color: #f83600;
  }

  ${mq.lessThan('medium', `
    font-size: 34px;
    text-align: center;

    img {
      display: none;
    }

    span {
      display: block;
      margin-top: 0.5em;
    }
  `)}
`;

const Features = styled.ul`
  width: 100%;
  max-width: 1000px;
  padding-inline: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const Feature = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Description = styled.div`
  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5em;

    img {
      width: 36px;
      height: auto;
    }
     
    &:first-of-type {
      ${typography.title}
      color: #212121;
      margin-bottom: 20px;
    }
    
    &:last-of-type {
      ${typography.description}
      font-weight: 400;
      line-height: 160%;
      color: #696969;
      white-space: pre-wrap;
    }
  }
`;

const FeatureImage = styled.img`
  width: 80%;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow:8px 8px 0px;
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid #999;
`;
