import mq from './mq';

const typography = {
  title: `
    font-size: 36px;
    font-weight: 600;

    ${mq.lessThan('medium', `
      font-size: 24px;
    `)}
  `,
  description: `
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;

    ${mq.lessThan('medium', `
      font-size: 15px;
    `)}
  `,
};

export default typography;
