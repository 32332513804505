const mq = {
  lessThan(size: string, styles: string) {
    return `
      @media (max-width: 768px) {
        ${styles}
      }
    `;
  },
};

export default mq;
