import YouTube, { YouTubeProps } from 'react-youtube';
import styled from 'styled-components';

export default function ServiceGuideVideo() {
  const options: YouTubeProps['opts'] = {
    playerVars: {
      rel: 0,
    },
    setPlaybackQuality: 'hd1080',
  };

  const videoId = 'Ye4hxqOWYe8';

  return (
    <Container>
      <YouTube
        videoId={videoId}
        opts={options}
        onReady={(e) => {
          e.target.setVolume(80);
        }}
        style={{
          backgroundColor: '#000',
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  >div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    border: 2px solid #000;
    border-radius: 12px;
    box-shadow: 8px 8px 0 0;
  }

  iframe {
    width: 100%;
    height: 101%;
  }
`;
