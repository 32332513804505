import styled from 'styled-components';
import mq from './style/mq';

function Footer() {
  return (
    <Wrapper>
      <Sponsors>
        <Sponsor src="https://cdn.worldvectorlogo.com/logos/openai-wordmark.svg" alt="OpenAi" />
        <Sponsor src="https://www.inthenews.co.kr/data/photos/uploads/2020/03/%E1%84%90%E1%85%B5%E1%86%B8%E1%84%89%E1%85%B3%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9v2.png" alt="팁스" />
        <Sponsor src="https://cdn.imweb.me/upload/S202311203c4fe1e200689/5c29594a35f46.png" alt="킹슬리 벤처스" />
      </Sponsors>
      <Info>
        <p>
          <span>(주) 엘리먼츠 | </span>
          <span>서울특별시 관악구 관악로 110 | </span>
          <span>대표이사: 이상연 | </span>
          <span>대표번호: 070-7174-3777</span>
        </p>
        <p>
          <span>사업자 등록번호 : 182-88-02666 | </span>
          <span>통신판매업신고번호 : 2023-서울관악-1966 | </span>
          <span>contact@elements.im</span>
        </p>
        <p>
          Copyright 2023 (주) 엘리먼츠 All Rights Reserved.
        </p>
      </Info>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fbfaff;
  padding-block: 40px;
  gap: 48px;

  ${mq.lessThan('medium', `
    align-items: flex-start;
    padding-inline: 2em;
  `)}
`;

const Sponsors = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  ${mq.lessThan('medium', `
    display: none;
  `)}
`;

const Sponsor = styled.img`
  height: 60px;
  width: auto;
`;

const Info = styled.div`

p {
  font-size: 14px;
    color: rgb(57, 57, 57);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  ${mq.lessThan('medium', `
    span {
      display: block;
      margin-bottom: 0.5em;
    }
  `)}
`;
