import styled from 'styled-components';

import WaitListButton from './components/WaitListButton';

import mq from './style/mq';
import ServiceGuideVideo from './components/ServiceGuideVideo';

function HeadSection() {
  return (
    <Wrapper>
      <Container>
        <Logo>
          <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/papyrus-favicon.png" alt="파피루스" />
          파피루스
        </Logo>
        <Description>
          <div>
            <Title>
              <p>대학원생을 위한</p>
              <p>
                논문
                {' '}
                <strong>Archive AI</strong>
              </p>
            </Title>
            <SubTitle>
              <p>스크랩만 하면 레퍼 정리부터 구조에 맞는 요약까지</p>
              <p>귀찮은 논문 정리는 파피루스에게 맡기고 연구에만 집중하세요.</p>
            </SubTitle>
          </div>
          <img src="https://geusaeng-public-images.s3.ap-northeast-2.amazonaws.com/icons/papyrus-favicon.png" alt="파피루스" />
        </Description>
        <WaitListButton />
        <ServiceGuideVideo />
      </Container>
    </Wrapper>
  );
}

export default HeadSection;

const Wrapper = styled.section`
  width: 100%;
  background-color: rgb(255, 230, 201);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 80px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  padding-inline: 32px;
  box-sizing: border-box;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
`;

const Logo = styled.p`
  font-size: 28px;
  font-weight: 700;

  img {
    width: 24px;
    height: auto;
    margin-right: 8px;
  }
`;

const Title = styled.div`
  margin-bottom: 24px;

  p {
    font-size: 60px;
    font-weight: 600;
    line-height: 120%;

    strong {
      font-size: 64px;
      font-weight: 900;
      color: #f83600;
    }

    ${mq.lessThan('medium', `
      font-size: 40px;

      span {
        font-size: 48px;
      }
    `)}
  }
`;

const Description = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  img {
    width: 240px;
    height: auto;
    margin-right: 20px;

    ${mq.lessThan('medium', `
      display: none;
    `)}
  }
`;

const SubTitle = styled.div`
  p {
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
    color: #333;

    ${mq.lessThan('medium', `
      font-size: 15px;
    `)}
  }
`;
