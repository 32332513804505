import styled from 'styled-components';

export default function WaitListButton() {
  return (
    <Button href="https://elements.im/4ajWEQN" target="_blank" rel="noreferrer noopener">
      사전 신청 하러가기
    </Button>
  );
}

const Button = styled.a`
    background-color: #000;
    padding: 18px 28px;
    color: #fff;
    border-radius: 12px;
    font-size: 20px;
    line-height: 100%;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: #333;
    }
`;
