import HeadSection from './HeadSection';
import GlobalStyle from './style/GlobalStyle';
import FeaturesSection from './FeaturesSection';
import WantWaitSection from './WantWaitSection';
import Footer from './Footer';

function App() {
  return (
    <>
      <GlobalStyle />
      <HeadSection />
      <FeaturesSection />
      <WantWaitSection />
      <Footer />
    </>
  );
}

export default App;
