import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
    ${reset}
    
    body {
        font-family: 'Noto Sans KR', sans-serif;
    }

    button,
    input,
    select,
    textarea {
        font-family: inherit;
        }
`;

export default GlobalStyle;
